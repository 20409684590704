@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Contrail+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");
* {
  box-sizing: border-box;
  transition: all 0.3s ease;
}

/* Variáveis do Projeto */
:root {
  --headerHeight: 55px;
  --footerHeight: 145px;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #181928;
  display: flex;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}
